@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

@import '@angular/material/theming';


$config: mat-typography-config(
  $font-family: 'Open Sans, sans-serif'
);

@include mat-core($config);

$app-primary: mat-palette($mat-grey);
$app-accent:  mat-palette($mat-light-blue);
$app-warn: mat-palette($mat-pink);

$accent : #ff4081; 
$accentRipple :  #ff40811c; 

mat-card.selected {
    border: 1px solid $accent;
    background: $accentRipple;
}




// mat-card .mat-ripple-element {
//     background-color: $accentRipple !important;
// }