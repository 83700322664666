/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif; 
  min-height: 90vh;
}
ul{
  list-style: none;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
.logo-wrapper {width: 125px;height: 25px;display: block;}
.logo-wrapper img{width: 100%;height: 100%;}

.login-wrapper {
  height: 100%;
  padding: 0 10px;
  background: linear-gradient(180deg,#06a8ff 0,#4f507a 100%);
}

.login-wrapper .auth-form,.mobile-form{
  min-width: 82vw;
  max-width: 350px;
  width: 82vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-wrapper .auth-full-width, .auth-full-width,
.login-wrapper .btn-block, .btn-block {
  width: 100%;
}

.login-wrapper mat-card-header {
  text-align: center;
  width: 100%;
  display: block;
  font-weight: 700;
}

.login-wrapper mat-card-header .mat-card-header-text{
  margin: 0;
}

.login-wrapper mat-card-header mat-card-title {
  font-size: 30px !important;
  margin: 0;
}

.login-wrapper .mat-card {
  padding: 10px 20px 60px;
  height: 95%;
}

.login-wrapper .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.8375em 0;
}
.login-wrapper .mat-button-toggle-group-appearance-standard {
  border: none;
  margin-top: 10px;
}
.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
  border-left: none;
}
.login-wrapper .mat-button-toggle-checked {
  color: white;
  background-color: #3f51b5 !important;
  opacity: 1 !important;
  box-shadow: #3f51b5 0px 0.0625em 0.0625em, #3f51b5 0px 0.125em 0.5em;
}
.login-wrapper .mat-button-toggle-group {
  flex-wrap: wrap;
}
.login-wrapper .mat-button-toggle {
  background-color: whitesmoke;
  font-weight: bold;
  opacity: 0.6;
  border-radius: 2px;
  margin: 10px 5px;
  padding: 8px 18px;
}
.login-wrapper .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.login-wrapper .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 18px;
}
.login-wrapper p {
  color: #6C6C6C;
  font-size: 16px;
  line-height: 20px;
  margin: 6px 0 0;
}

.progress-spinner {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  background:  rgba(229, 224, 223, 0.315);
}

@media screen and (max-width: 420px) {
  .otp-input {
      width: 50px !important;
      height: 50px !important;
  }
}

/* business profile */
.business-section .form, .schedule-call form {
  display: block;
  height: auto;
  flex-direction: inherit;
}
.business-section .mat-input-element{
  line-height: 1;
}
.business-section .google-review-sec .mat-form-field-infix{
  line-height: 2;
}
.container-view {
  padding: 64px 20px 20px;
  height: 100%;
  overflow-y: scroll;
}
.form {
  height: 100%;
  display: flex;
  flex-direction: column;
}
button.mat-raised-button{
  max-width: fit-content;
  width: 100%;
}
.terms-link {
  margin-left: 4px;
  margin-top: 2px;
}
.logo-section{
  line-height: 1;
}
.mat-error{
  padding-bottom: 15px;
}
.business-section .mat-form-field{
  margin-top: 10px;
}

.container-view.business-section{ 
  overflow-y: inherit;
}

mat-card-title.card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
}
mat-card-title.card-title {
  font-size: 20px;
  line-height: 25px;
}

/* suvey preview */
.ppoup-section ul{
  padding-left: 0;
  margin-bottom: 5px;
}
.ppoup-section ul li {
  display: inline-block;
  vertical-align: middle;
  padding: 0 5px;
}
.ppoup-section ul li span{
  margin-right: 10px;
}
.survey-section ul.matrix-table-width{
  background-color: #fff;
  box-shadow: -1px 2px 4px 0 rgb(206 206 206);
  padding: 20px 10px;
  border-radius: 10px;
}
.survey-section ul.matrix-table-width li{
  display: block;
}
.survey-section ul.matrix-table-width li label{
  position: relative;
  display: block ;
}
.survey-section ul.matrix-table-width li label input{
  opacity: 0;
  position: absolute;
}
.survey-section ul.matrix-table-width li label span{
  width: calc(100% - 30px);
  display: inline-block;
  margin-right: 0;
  padding: 5px 10px 5px 45px;
}
.survey-section h3.matrix-hdg{
  color: #000000;
  margin-bottom: 10px;
  box-shadow: -1px 2px 4px 0 rgb(220 220 220);
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  line-height: 1.4;
  font-size: 16px;
  line-height: 1.3;
  word-break: break-word;
}
.survey-section ul.matrix-table-width li label span::before{
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  width: 20px;
  height: 20px;
  z-index: 0;
  -webkit-transition: .28s ease;
  border-radius: 50%;
  transition: .28s ease;
  border: 1px solid #b0afaf !important;
}
.survey-section ul.matrix-table-width li label span::after{
  content: '';
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    z-index: 0;
    -webkit-transition: .28s ease;
    transition: .28s ease;
}
.survey-section .qu-tab-boxqu-tab-box h3{
  margin-bottom: 0;
  font-size: 15px;
  text-transform: capitalize;
}
.survey-section ul.matrix-table-width li h3{
  font-size: 15px;
  text-transform: capitalize;
}
.survey-section ul.matrix-table-width .coloumn_text{
  background-color: #ffffff;
  box-shadow: -1px 2px 4px 0 rgb(206 206 206 / 50%);
  padding: 10px;
  margin-bottom: 15px;
}
.survey-section .hover-qu-box span.clicktext{
  margin-bottom: 10px;
  display: block;
}
.survey-section .hover-qu-box span.clicktext i{
  width: 16px;
  font-size: 0;
  line-height: inherit;
  margin-right: 8px;
  display: inline-block;
  vertical-align: top;
}
.survey-section .hover-qu-box span.clicktext i img{
  width: 100%;
}
.survey-section .hover-qu-box span.clicktext span{
  display: inline-block;
  width: 91%;
  font-style: italic;
  font-size: 13px;
}
.survey-section .ppoup-section h1{
   margin-bottom: 10px;
   font-size: 22px;
   line-height: 1.3;
}
.survey-section.container-view .ppoup-section h1{
  padding: 0 15px;
  text-align: center;
}
.survey-section .top-section h2{
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 1.3;
}
.survey-section .top-section p{
  margin-bottom: 5px;
}
.survey-section .list-sec h5{
  margin-bottom: 0;
}
.survey-section .list-sec h3{
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 1.4;
}
.ppoup-section ul li .list-sec {
  display: inline-block;
  vertical-align: middle;
}
.survey-section.container-view{
  padding: 64px 0 5px;
}
.survey-section .bottom-section button.mat-raised-button{
  margin: 8px auto;
  display: block;
}
.ppoup-section .gray-box {
  padding: 5px 16px 5px;
}

.gray-box {
  margin: 0 -34px -25px;
  padding: 27px 40px;
  border-radius: 4px;
}
.bg-color{
  background-color: #f5f5f5;
}
.qu-star-rating{display: flex;}
.qu-star-rating li{position: relative;margin-right: 6px;}
.qu-star-rating input{opacity: 0;position: absolute;width: 100%;height: 100%;left: 0;cursor: pointer}
.ppoup-section ul.qu-star-rating li{
  padding: 0 5px;
}
.hover-qu-box{padding: 5px 0;}
.qu-tab-box{border-radius: 4px;background-color: #F5F5F5;padding: 0 34px 34px;;border-top-right-radius: 0;}
.qu-tab-box h3{font-weight: 500;padding-top: 30px;padding-left: 42px;position: relative;}
.qu-tab-box h3 strong{margin-right: 24px;font-weight: 600;position: absolute;left: 0;}
.qu-tab-box .qu-checkbox{max-width: 378px;}
.qu-tab-box .upload-img{border: 1px solid #767F8C;display: flex;justify-content: center;align-items: center;min-height: 262px;background: #fff;border-radius: 2px;}

.qu-tab .dropdown{width: 100%}
.qu-tab .dropdown>.dropdown-menu {opacity: 0;visibility: hidden;margin-left: 24px;margin-top: -9px;top: 1px !important;box-shadow: none;border-radius: 0;left: 0px;transform: translate3d(-543px, 0px, 0px);right: auto;animation: none;width: 520px;padding: 8px;padding-right: 0;-webkit-transition: all 0.4s ease-in-out;-moz-transition: all 0.4s ease-in-out;-o-transition: all 0.4s ease-in-out;transition: all 0.4s ease-in-out; animation-name :example;animation-duration: 1.1s;}
.qu-tab .dropdown-menu:after{content: none;}
.qu-tab .dropdown-toggle::after{content: none;}
.qu-tab .dropdown .dropdown-menu.bottom-dropdown{top: auto !important;bottom: -8px;}
.qu-tab .dropdown:hover .dropdown-menu{z-index: 9;display: block;opacity: 1;visibility: visible;  -webkit-transition: all 0.4s ease-in-out;moz-transition: all 0.4s ease-in-out; -o-transition: all 0.4s ease-in-out;transition: all 0.4s ease-in-out; }
.qu-tab .qu-tab-box{pointer-events: none;    border-bottom-right-radius: 0;}
.qu-tab .dropdown:last-child .dropdown-menu,.qu-tab .dropdown:nth-last-child(2) .dropdown-menu{bottom: -7px;top: auto !important;margin-left: 3px;}
.qu-tab .dropdown:nth-child(7) .dropdown-menu{top: -120px !important;}
.hover-qu-box table.matrix-table input[type=radio] ~ .checkmark{top: 0;}

ul.qu-rating{display: flex;border: 1px solid #767F8C;border-radius: 4px;background: #fff;  max-width: 100%; width: 100%;}
ul.qu-rating li{border-right: 1px solid #767F8C;width: 100%;height: 34px;display: flex;align-items: center;justify-content: center;position: relative;padding: 0 5px;}
ul.qu-rating li:last-child{border:none;}
ul.qu-rating input{opacity: 0;position: absolute;width: 100%;height: 100%;}
.qu-tab h3.remaining{font-size: 15px;color: #767F8C;}

.qu-tab-box{border-radius: 4px;background-color: #F5F5F5;padding: 0 34px 34px;;border-top-right-radius: 0;}
.qu-tab-box h3{font-weight: 500;padding-top: 30px;padding-left: 42px;position: relative;}
.qu-tab-box h3 strong{margin-right: 24px;font-weight: 600;position: absolute;left: 0;}
.qu-tab-box .qu-checkbox{max-width: 100%;}
.qu-tab-box .upload-img{border: 1px solid #767F8C;display: flex;justify-content: center;align-items: center;min-height: 262px;background: #fff;border-radius: 2px;}
.matrix-table thead th,.matrix-table tr td{font-weight: 500;font-size: 16px;border:none;padding: 15px;}
.matrix-table thead th{padding-top: 0;}
.matrix-table .custom-checkbox{height: 15px;}
.matrix-table tbody tr:nth-child(odd){background-color: #E8E8E8;border-radius: 4px;}
.matrix-table .checkmark{border: 1px solid #242424;border-radius: 50%;}
.matrix-table tr td:first-child{width: 160px;border-top-left-radius: 4px;border-bottom-left-radius: 4px;}
.matrix-table tr td:last-child{border-top-right-radius: 4px;border-bottom-right-radius: 4px;}

/* checkbox */
.custom-checkbox {position: relative;padding-left: 32px;cursor: pointer;font-size: 22px;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;
  user-select: none;font-size: 15px;color: #767F8C;line-height: 19px;margin: 0;}
  .custom-checkbox input {position: absolute;opacity: 0;cursor: pointer;height: 0;width: 0;}
  .checkmark {position: absolute;top: 0;left: 0;height: 20px;width: 20px;border-radius: 4px;border: 1px solid #D8DFE9;}
  .custom-checkbox input:checked ~ .checkmark,.qu-tab ul.qu-setting .custom-checkbox input:checked ~ .checkmark  {background-color: #EAA529;border-color: #EAA529;}
  .checkmark:after {content: "";position: absolute;display: none;}
  .custom-checkbox input:checked ~ .checkmark:after {display: block;}
  .custom-checkbox .checkmark:after {left: 6px;top: 1px;width: 6px;height: 13px;border: solid white;border-width: 0 2px 2px 0;-webkit-transform: rotate(45deg);-ms-transform: rotate(45deg);transform: rotate(45deg);}
  .custom-radio {display: block;position: relative;padding-left: 33px;margin-bottom: 24px;cursor: pointer;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;font-size: 16px;color: #222222;line-height: initial;}
  .custom-radio input {position: absolute;opacity: 0;cursor: pointer;}
  .checkmark-radio {position: absolute;top: 0;left: 0;height: 20px;width: 20px;background-color: #fff;border-radius: 50%;border: 1px solid #D8DFE9;}
  .custom-radio input:checked ~ .checkmark-radio {background-color: #EAA529;border-color: #EAA529;}
  .checkmark-radio:after {content: "";position: absolute;display: none;}
  .custom-radio input:checked ~ .checkmark-radio:after {display: block;}
  /*.custom-radio .checkmark-radio:after {left: 7px;top: 2px;width: 6px;height: 13px;border: solid white;border-width: 0 2px 2px 0;-webkit-transform: rotate(45deg);-ms-transform: rotate(45deg);transform: rotate(45deg);}*/
  .hover-qu-box .custom-checkbox input[type=radio] ~ .checkmark{border-radius: 50%;width: 16px;height: 16px;top: 11px;}
  .hover-qu-box .custom-checkbox input[type=radio] ~  .checkmark:after {left: 6px;height: 11px;top: 2px;}

  .qu-checkbox{border: 1px solid #767F8C;width: 100%;padding: 12px 18px;background-color: #FFFFFF;border-radius: 2px;color: #222222;font-size: 14px;}
.qu-checkbox .checkmark{right: 10px;left: auto;top: 13px;height: 17px;width: 17px;border: 1px solid #767F8C;border-radius: 2px;background-color: #FFFFFF;top: 50%;transform: translateY(-50%);}
.qu-checkbox .checkmark:after {left: 5px;height: 10px;}
.ppoup-section .qu-tab-box{
  padding: 0;
}
.ppoup-section .qu-tab-box h3{
  color: #000000;
  margin-bottom: 10px;
  padding-top: 0;
  padding-left: 20px;
  font-size: 13px;
  line-height: 1.3;
  word-break: break-word;
}
.heading span{
  padding: 0px 16px;
  font-size: 20px;
  font-weight: bolder;
}
.ppoup-section .qu-tab-box .qu-checkbox:first-child{
  margin-left: 0;
}
.ppoup-section .qu-tab-box .qu-checkbox:last-child{
  margin-right: 0;
}
.ppoup-section .hover-qu-box .custom-checkbox input[type=radio] ~ .checkmark{
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
}
.ppoup-section .qu-tab-box .qu-checkbox{
  border: 1px solid #6d435a;
  border-radius: 5px;
  cursor: default;
  padding: 5px 32px 5px 8px;
  font-size: 13px;
}
.ppoup-section .qu-tab-box .qu-checkbox img{
  width: 100px;
}
.ppoup-section .single-responsive .qu-tab-box .qu-checkbox{
  max-width: 150px;
  display: inline-block;
  margin: 0 5px;
}
.ppoup-section .single-responsive .qu-tab-box .qu-checkbox:first-child{
  margin-left: 0;
}
.ppoup-section .single-responsive .qu-tab-box .qu-checkbox:last-child{
  margin-right: 0;
}
.ppoup-section .qu-tab-box .upload-img{
  max-width: 100%;
  border: 1px solid #622446;
}
.ppoup-section textarea.form-control{
  resize: none;
  max-width: 95%;
  border: 1px solid #622446;
  font-size: 13px;
  color: #222222;
}

.ppoup-section .bottom-section{
  padding: 10px 0;
  text-align: center;
}
.survery-preview-setion .top-section ul{ 
  padding-left: 0;
}
.survery-preview-setion .top-section ul li{
  padding: 0 5px;
}
.survery-preview-setion .top-section ul li:first-child{
  padding-left: 0;
}
.survery-preview-setion .top-section ul li:last-child{
  padding-right: 0;
}
.survery-preview-setion .top-section ul li span{
  margin-right: 5px;
}
.survery-preview-setion .list-sec h5{
  margin-bottom: 0;
}
.survery-preview-setion .list-sec h3{
  font-size: 14px;
}
.survery-preview-setion .ppoup-section h1{
  margin-bottom: 10px;
}
.survery-preview-setion .bottom-dropdown ul{
  padding-left: 0;
}
.survery-preview-setion .bottom-section h6{
  margin-top: 0;
}
.survery-preview-setion.container-view{
  padding: 10px 15px 5px 15px;
}
.qr-section.container-view{
  overflow-y: inherit;
  height: auto;
}
.qr-section .form{
  display: block;
  text-align: center;
  height: auto;
  flex-direction: inherit;
}
.mat-typography .qr-section h1{
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1;
  font-size: 22px;
}
.mat-typography .qr-section h2{
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 10px;
  line-height: 1.3;
}
.qr-section ol{
  margin-bottom: 10px;
  padding-left: 20px;
}
.qr-section ol li{
  padding: 4px 0;
}
/* .choose-plan-section .mat-card{
  width: 100%;
}
.choose-plan-section .mat-radio-label-content{
  width: 100%;
}
.choose-plan-section .mat-radio-button{
  width: 100%;
}
.choose-plan-section .coming-soon-secton{
  width: 98%;
} */
.choose-plan-section .mat-card{
  border-radius: 20px;
  padding: 16px 25px;
  background: #b4abab1c;
  box-shadow: 1px 2px 3px 0px rgb(0 0 0 / 52%);
  border: none;
}
.choose-plan-section .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color: #3f51b5;
}
.choose-plan-section .mat-radio-button.mat-accent .mat-radio-inner-circle{
  background-color: #3f51b5;
}
.choose-plan-section mat-card-title.card-title{
  color: #000000;
  font-weight: bold;
  margin-bottom: 2px;
}
.choose-plan-section .mat-card-content{
  margin-bottom: 0;
  color: #959393;
}
.choose-plan-section label.mat-radio-label{
  display: inline-block;
  width: 100%;
  position: relative;
}
.choose-plan-section label.mat-radio-label span.mat-radio-label-content{
  padding-left: 0;
  display: block;
}
.choose-plan-section .mat-radio-button{
  width: 100%;
}
.choose-plan-section .mat-card{
  width: 100%;
}
.choose-plan-section label span.mat-radio-container{
  position: absolute;
  z-index: 10;
  margin-left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.exist-section.setup-section .mat-card {
  display: flex;
  align-items: center;
}
.setup-section .mat-card-title{
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 0;
  text-align: left;
  font-weight: bold;
}
.strategy-section .exist-feedback-sec {
  margin-top: 7px;
}
.setup-section .qua-section label{
  font-size: 13px;
  font-weight: bold;
  color: #959393;
  margin: 0 2px 0 2px;
}
.setup-section .qua-section label:first-child{
  margin-left: 0;
}
.setup-section .qua-section label:last-child{
  margin-right: 0;
}
.numbers-section{
  float: left;
  margin-right: 15px;
  position: relative;
}
.strategy-section .mat-card{
  padding-left: 16px;
}
.stargy-launch-sec{
  float: left;
  text-align: left;
}
.strategy-section .numbers-section:after{
  content: 'Responses';
  position: absolute;
  left: 7px;
  bottom: -17px;
  font-size: 9px;
}
.setup-section .mat-card .numbers-section span{
  padding-right: 0;
}
.numbers-section span{
  border: 1px solid transparent;
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 0;
  background: #3f51b5;
  color: #fff;
  border-radius: 15px;
  min-width: 60px;
  min-height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.numbers-section span.three{
  font-size: 30px;
}
.numbers-section span.four{
  font-size: 22px;
}
.strategy-section .mat-card-title:not(:first-child), .mat-card-subtitle:not(:first-child){
  margin-top: 5px;
}
.strategy-section .launch-section.mat-card-title{
  font-size: 13px;
  color: #959393;
  margin-bottom: 3px;
  margin-top: 0;
}
.strategy-section .scanning-section img{
  height: 20px;
}
.stargy-launch-sec .mat-card-title{
  font-size: 18px;
}
.numbers-section  h6{
  font-size: 9px !important;
  margin-bottom: 0  !important;
}
.setup-section .mat-card span img{
  width: 35px;
}
/* .setup-section .mat-card .numbers-section span{
  padding-right: 15px;
} */
.exist-section.setup-section .mat-card .card-title.date{
  font-size: 12px;
  color: #959393;
}
.exist-section.setup-section .exist-feedback-sec{
  float: left;
}
.exist-section.setup-section .exist-feedback-sec .mat-card-title{
  float: none;
  text-align: left;
  margin-top: 0;
  font-size: 18px;
}
.setup-section .mat-card-title span{
  padding-right: 25px;
}
.setup-section.container-view{
  height: auto;
}
.setup-section label{
  margin: 0 5px 0 5px;
}
.container-view h1.survey-hedg{
  margin: 10px 0;
  font-size: 22px;
  line-height: 1.3;
  text-align: center;
}
.qr-sucess-section .form{
  justify-content: center;
  text-align: center;
}
.qr-content img{
  width: 120px;
  margin-bottom: 20px;
}
.qr-content h3{
  line-height: 1.4;
}
.strategy-section .form{
  justify-content: center;
}
.qr-section .business-hdg{
  font-size: 20px;
  line-height: 1.4;
  overflow: auto;
  white-space: nowrap;
  display: block;
  font-weight: bold;
}
.no-data-section{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.help-earn-section .mat-form-field{
  margin-top: 10px;
}
.help-earn-section.login-wrapper .mat-card{
  overflow-y: auto;
}
.help-earn-section .form {
  height: auto;
}
.help-earn-section  .mat-form-field-infix{
  line-height: normal;
}
.insight-section.container-view{
  overflow-y: inherit;
}
.insight-section .mat-form-field-infix .mat-select-trigger{
  margin-top: 14px;
  font-size: 18px;
}
.insight-section .mat-form-field-label-wrapper label.mat-form-field-label{
  color: #000;
  font-size: 20px;
  margin-bottom: 20px;
  transform: none;
}
.insight-section .mat-form-field-suffix button.mat-icon-button{
  vertical-align: bottom;
}
.cdk-overlay-container .mat-form-field{
  margin-top: 10px;
}
.cdk-overlay-container .mat-form-field-infix{
  line-height: 2;
}
.insight-section .mat-form-field-label-wrapper label.mat-form-field-label mat-label{
  font-size: 18px;
}
.insight-section .mat-form-field-appearance-fill .mat-form-field-infix{
  padding: 1.25em 0 0.75em 0;
}
.mat-bottom-sheet-container button.mat-raised-button{
  width: auto;
}
.main-bg-section{
  background-color: #ffffff; 
  border-radius: 20px;
}
.bg-top-section{
  background-color: #3f51b5;
  padding: 15px 15px 7px;
  border-radius: 20px;
}
.bg-top-section .dashboard-img-sec img{
  width: 38px;
  background-color: #ffffff;
  padding: 3px;
  border-radius: 10px;
}
.main-bg-section .dashboard-img-sec{
  float: left;
  width: 40px;
}
.main-bg-section .hdg-section{
  float: left;
  width: calc(100% - 40px);
}
.main-bg-section .hdg-section h3{
  overflow-wrap: break-word;
}
.bottom-detail-sec{
  padding: 10px 15px;
}
.bottom-detail-sec .circles{
  float: left;
}
.bottom-detail-sec .call-content-area{
  width: calc(100% - 60px);
  float: right;
}
.hdg-section{
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
}
.dashboard-img-sec{
  display: inline-block;
  vertical-align: top;
}
.mat-card .hdg-section h3{
  display: block;
  margin-bottom: 0;
  color: #ffffff;
  font-size: 20px;
  overflow: auto;
  white-space: nowrap;
}
.mat-card .hdg-section h3::-webkit-scrollbar, .bottom-detail-sec a.card-title::-webkit-scrollbar,.call-area-sec mat-card-title.card-title::-webkit-scrollbar, .schedule-call mat-card-title.small-hdg::-webkit-scrollbar, .otp-section p::-webkit-scrollbar, .qr-section .business-hdg::-webkit-scrollbar {
  display: none;
}
.mat-card .hdg-section h3, .bottom-detail-sec a.card-title, .call-area-sec mat-card-title.card-title, .schedule-call mat-card-title.small-hdg, .otp-section p, .qr-section .business-hdg{
  -ms-overflow-style: none;
  scrollbar-width: none; 
}
.mat-card .date-time-section h6{
  font-size: 12px;
  color: #b7b3b3;
  font-weight: normal;
  margin-bottom: 0;
  line-height: 1;
}
.mat-card .date-time-section h4{
  color: #ffffff;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 1;
}
.date-time-section .date-left-sec{
  display: inline-block;
  vertical-align: middle;
}
.date-time-section .time-right-sec{
  display: inline-block;
  vertical-align: middle;
  padding-left: 80px;
}
.call-main-section.schedule mat-card.selected.call-area-sec{
  padding: 0;
}
.call-main-section mat-card.selected .call-area-sec.top{
  border: none;
}
.call-main-section.schedule.container-view{
  overflow-y: inherit;
  height: auto;
}
.bottom-detail-sec a.card-title{
  font-size: 16px;
  overflow: auto;
  white-space: nowrap;
  display: block;
}
.call-area-sec mat-card-title.card-title{
  text-transform: lowercase;
  line-height: 2;
  font-size: 15px;
  overflow: auto;
  white-space: nowrap;
  display: block;
  text-overflow: inherit;
}
.bottom-icon-section ul{
  margin-bottom: 0;
}
.bottom-icon-section ul li{
  display: inline-block;
  padding: 0 5px;
}
.bottom-icon-section ul li a img{
  height: 24px;
  width: 24px;
}
.call-main-section.schedule mat-card.selected{
  border-radius: 20px;
  padding: 0px 25px;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 52%);
  -moz-box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 52%);
  box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 52%);
  border: none;
}
.dashboard-section mat-card.selected,.setup-section .mat-card,
.schedule mat-card.selected,.schedule-call mat-card.selected {
  border-radius: 8px;
  padding: 16px;
  background: white;
  border: none;
  cursor: pointer;
}
.setup-section .mat-card{
  padding: 16px 30px;
}
.setup-section .mat-card{
  padding: 16px 30px;
}
.dashboard-section .dashboard-img-sec{
  float: left;
  width: 76px;
  text-align: center;
}
.dashboard-section .mat-card-title{
  font-size: 22px;
}
.dashboard-section .dashboard-img-sec img{
  width: 60px;
  background-color: #3f51b5;
  padding: 10px;
  border-radius: 15px;
}
.dashboard-section .mat-card-title{
  display: block;
  margin-bottom: 0;
  text-align: left;
  padding-left: 25px;
  line-height: 1.3;
  color: #000000;
  margin-top: 0 !important;
}
.dashboard-section .mat-card-title.small-hdg{
  font-size: 14px;
  color: #959393;
  font-weight: normal;
  text-transform: inherit;
  line-height: 18px;
}
.dashboard-section .mat-card-title:not(:first-child), .mat-card-subtitle:not(:first-child){
  margin-top: 3px;
}
@media screen and (max-width: 767px){
  .content {
      margin-top: 60px;
  }
}

@media screen and (max-width: 767px){
  .content {
      font-size: 20px;
      width: 100%;
      margin-left: 0;
      text-align: center;
  }
  .img-wrap {
    margin: 0 auto 35px;
  }
  .setup-section .qua-section label{
    font-size: 12px;
  }
  .setup-section .mat-card{
    padding: 16px;
  }
  .exist-section.setup-section .exist-feedback-sec{
    width: 82%;
  }
  .stargy-launch-sec{
    width: 75%;
  }
}
@media screen and (max-width: 379px){
  .exist-section.setup-section .exist-feedback-sec{
    width: 77%;
  }
  .stargy-launch-sec{
    width: 69%;
  }
}
@media screen and (max-width: 350px){
  .dashboard-section .mat-card-title{
    padding-left: 15px;
  }
}

.img-wrap {
  width: 120px;
  height: 120px;
  display: block;
  margin-bottom: 35px;
}

.title {
  margin-bottom: 10px;
  color: #242424;
}

/* @media screen and (max-width: 767px){
  figure.added-logo {
      margin: 40px auto 90px auto;
  }
} */

figure.added-logo {
  width: 155.56px;
  height: 80px;
  position: relative;
  display: flex;
  /* margin: 40px 0 90px 0; */
  margin: 40px auto 90px auto;
}
.business-section .proceed-section{
  height: 55px;
}
.business-section .mat-form-field-infix{
  line-height: 1.6;
}
.image-list .remove{
  top: 50%;
  transform: translateY(-50%);
  bottom: 0;
}
.image-list .remove img{
  top: 0;
}
.schedule.container-view{
  overflow-y: inherit;
}
.schedule h1{
  display: inline-block;
}
.schedule .mat-stroked-button {
  padding: 0px 5px;
  border-radius: 50%;
  min-width: 35px;
  font-size: 12px;
  float: right;
  margin-top: -4px;
}

.schedule .add-container{
  text-align: center;
}
.schedule-empty-section{
  display: flex;
  align-items: center;
  height: 100%;
}
.schedule-call.container-view{
  height: auto;
  overflow-y: inherit;
}
.schedule-call .add-container{
  display: flex;
  align-items: center;
}
.schedule-call .mat-form-field{
  margin-top: 10px;
}
.schedule-call .mat-form-field-infix{
  line-height: 1;
}
.mat-form-field-infix{
  line-height: 1.5;
}
.schedule-call mat-card.selected.main-schedule-section{
  padding: 10px 15px;
}
.main-schedule-section .bottom-detail-sec{
  padding: 0; 
}
.main-schedule-section .bottom-detail-sec .call-content-area{
  width: calc(100% - 90px);
  float: left;
}
.main-schedule-section .bottom-detail-sec button.mat-icon-button{
  width: 24px;
  float: right;
}
.schedule-call .mat-stroked-button {
  padding: 0px 5px;
  border-radius: 50%;
  min-width: 35px;
  font-size: 4px;
}

.circles{
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #eee;
  border : 2px solid #fff;
  text-align: center;
  line-height: 46px;
}
.schedule-call mat-card-title.small-hdg {
  font-size: 15px;
  text-transform: lowercase;
  overflow: auto;
  white-space: nowrap;
  display: block;
  text-overflow: inherit;
}
.otp-section p{
  overflow: auto;
  display: block;
  width: 100%;
}
.showloader {
  text-align: center;
  margin-top: 25px;
}

.end-page-area .title{
  line-height: 1.4;
}
.end-page-area button.mat-raised-button{
  max-width: 205px;
}
.qr-main-section{
  position: relative;
}
.qr-main-section img.qr-img{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  width: 65px;
  margin: 0 auto;
}
.googlechart_bar .google-visualization-tooltip {
  z-index: 0;
}
.main.tc-popup-section {
  color: #818181;
}
.mat-typography .tc-popup-section h1{
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.tc-popup-section ol, .tc-popup-section ol{
  padding-left: 30px;
  margin-top: 20px;
}
.tc-popup-section p, .tc-popup-section span{
  margin-bottom: 20px !important;
  display: block;
}
.tc-popup-section div p{
  margin-bottom: 0 !important;
}
.pi-mobile-section .mat-form-field-wrapper span label{
  line-height: 1.5;
}
.mat-calendar-body-preview-end> .mat-calendar-body-cell-content {
  background-color: rgba(0, 0, 0, 0.04)!important;
}
.pac-item{
  padding: 7px 4px 4px 4px;
  overflow: visible;
  white-space: pre-wrap;
  line-height: 1.4;
}
.pac-logo .pac-item span.pac-icon{
  display: none;
}
.pac-logo .pac-item span.pac-item-query{
  display: block;
  line-height: 1;
}
.pac-logo:after{
  display: none;
}

.business-section .mat-chip-input {
  width: 45px;
  flex: 1 0 45px;
}
.pac-container{
  z-index: 1 !important;
}
.business-section .mat-chip-with-trailing-icon{
  max-width: 100%;
  margin: 4px 0 !important;
}
.business-section .buisness-cnt-area{
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mobile-sec-area{
  min-height: 84vh;
  max-height: 100vh;
  overflow-y: auto;
}

.invoice-summary mat-card.invoice,.payment-failed mat-card.payment {
  border-radius: 20px;
  padding: 16px;
  box-shadow: 1px 2px 3px 0px rgb(0 0 0 / 52%);
  border: none;
}

.recharge-btn.mat-mini-fab .mat-button-wrapper {
  line-height: 22px;
}

.growth-section label span.mat-radio-container{
  opacity: 0;
}

/* .business-profile .mat-radio-outer-circle{
  border-radius: 0;
  width: 16px;
  height: 16px;
}
.business-profile .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border: none;
  background-color: #ff4081;
}
.business-profile .mat-radio-inner-circle{
  left: 6px;
  top: 3px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg) !important;
  -ms-transform: rotate(45deg) !important;
  transform: rotate(45deg) !important;
  transition: none;
  border-radius: 0;
} */

.success-icon {
  height: 110px !important;
  width: auto !important;
}

.primary {
  color: #3f51b5;
}

.invoice-summary mat-card.invoice,.payment-failed mat-card.payment {
  border-radius: 20px;
  padding: 16px;
  box-shadow: 1px 2px 3px 0px rgb(0 0 0 / 52%);
  border: none;
}

.invoice .transaction_id {
  word-break: break-all;
}

.mat-tooltip {
  background-color:white;
  height: auto;
  color: #000 !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 13px;
  padding: 8px;
  text-align: center;
  box-shadow: 0 1px 3px 0 rgb(28 28 28 / 35%);
}
.mat-tooltip::after {
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.5rem solid white;
}
.mat-tooltip.below {
  overflow: initial;
  margin-top: 0.3rem;
}
.mat-tooltip.below:after {
  top: -0.5rem;
  right: 0.7rem;
  transform: rotate(0);
}

.business-profile .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon-button .mat-icon {
  font-size: 150% !important;
}

ul.breadcrumb-section {
  padding: 10px 5px;
  list-style: none;
}

ul.breadcrumb-section li {
  display: inline;
  font-size: 14px;
}

ul.breadcrumb-section li+li:before {
  padding: 8px;
  color: black;
  content: ">\00a0";
}

ul.breadcrumb-section li a {
  color: #0275d8;
  text-decoration: none;
}

ul.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: underline;
}
.choose-template .mat-list-base .mat-list-item .mat-list-item-content-reverse,
.choose-template .mat-list-base .mat-list-option .mat-list-item-content-reverse,
.choose-template .mat-list-base .mat-list-item.mat-list-option .mat-list-item-content-reverse .mat-list-text,
.choose-template .mat-list-base .mat-list-option.mat-list-option .mat-list-item-content-reverse .mat-list-text {
    padding: 0;
}
.analyze-tooltip {
  margin: 10px 0 24px 0 !important;
  white-space: pre-wrap;
}

.choose-plan-section .mat-tab-body-content {
  padding-top: 30px;
}
.choose-plan-section .mat-expansion-panel-body {
  padding: 0;
  margin-top: 16px;
}
.choose-plan-section .mat-tab-header {
  border-bottom: 0px;
}
.choose-plan-section .mat-tab-label{
  opacity: 1;
  height: 35px;
  padding: 0 12px;
}
.choose-plan-section .mat-tab-label.mat-tab-label-active{
  color:white !important;
  background: #3f51b5;
  opacity: 1;
  border-radius: 25px;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.choose-plan-section .mat-tab-group.mat-primary .mat-ink-bar {
  background:none;
}
.recommendation-description span.green {
  color: green !important;
}
.recommendation-description span.orange {
  color: orange !important;
}
.recommendation-description span.red {
  color: red !important;
}
.offer-route {
  font-size:12px;
  line-height:1.5;
}
.view-statistics .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 14px;
}

.choose-language .mat-radio-button label.mat-radio-label {
  margin-bottom: 0;
}
.free_trial{
  color: #3f51b5;
  font-size: 20px;
}
.draft-auto{
  color: #fff;
  border-radius: 8px;
  padding: 1px 6px 2px;
  font-size: 10px;
  line-height: 14px;
  background: #eaa529;
}